$primary: #1F5053;
$secondary: #439D9D;
$secondary-dark: #198181;
$gray: #e4e4e4;
$button: #E84D19;
$header: rgba(#1F5053, .75);

$theme-colors: (
    "secondary-dark": $secondary-dark,
    "gray": $gray,
    "button": $button,
    "header": $header,
);

:root {
    --doc-height: 100svh;
}

@import '~bootstrap';

// Generate `.fill-*` color utilities
@each $color, $value in $theme-colors {
    .fill-#{$color} {
        fill: $value
    }
    .stroke-#{$color} {
        stroke: $value
    }
}


@font-face {
    font-family: 'Default OBS Font';
    src: url('../fonts/roboto-regular.woff') format('woff');
}

@font-face {
    font-family: 'Default OBS Font';
    src: url('../fonts/roboto-bold.woff') format('woff');
    font-weight: bold;
}

@font-face {
    font-family: 'Default OBS Font';
    src: url('../fonts/roboto-light.woff') format('woff');
    font-weight: 300;
}

.fa-36 {
    font-size: 36px;
    @include media-breakpoint-down(lg) {
        font-size: 25px;
    }
}
.fa-46 {
    font-size: 46px;
}
.fa-51 {
    font-size: 51px;
}
.fa-30 {
    font-size: 30px;
}

// Responsive-Design Anpassungen

h1,h2,h3,h4,h5,h6,
.h1,.h2,.h3,.h4,.h5,.h6 {
    hyphens: auto;
}
h1, .h1 {
    font-size: 2rem;
}
.display-4 {
    font-size: 2.4rem;
}
h4, .h4 {
    font-size: 1.2rem;
}
@include media-breakpoint-up(lg) {
    .display-4 {
        font-size: 3.4rem;
    }
    h4, .h4 {
        font-size: 1.5rem;
    }
}

.mbeon-logo {
    position: absolute;
    top: 0;
    left: 10px;

    @include media-breakpoint-down(lg) {
        z-index: 1040;
    }
}

.logo-overview {
    max-width: 68px;
    background-color:$white;
    margin-left: -10px;
}
@include media-breakpoint-down(xs) {
    .logo-overview {
        display: none;
    }
}
@include media-breakpoint-up(lg) {
    .logo-overview {
        display: block;
        max-width: 190px;
        background-color:inherit;
    }
}
@include media-breakpoint-between(sm,lg) {
    .head-profil {
        padding-left: 80px;
    }
}

.navbar-toggler {
    padding: .125rem .125rem;
    border-color: $white;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: center center;
}
.navbar-collapse {
    min-width: 300px;
    position: fixed;
    top: 68px;
    right: 0;
    padding: 1.5rem;
    background-color: $white;
    z-index: 3000;
    box-shadow: 0 5px 5px rgba(0,0,0,0.25);
}

.footer {
    height: auto;
    width: 100%;
    line-height: 1.2;
    padding:0;
    position: fixed;
    bottom: 0;
    z-index: 1040;
    .container-fluid {
        padding-top: 1rem;
        padding-bottom: 2rem;
    }
    a {
        color: inherit;
        text-decoration: inherit;
    }
}

@include media-breakpoint-up(lg) {
    .nav-footer {
        margin-top: 7px;
    }
    .btn-maxwidth {
        max-width: 450px;
    }
}

.box-themen {
    border-left: none;
    @include media-breakpoint-up(lg) {
        border-left: 1px solid $secondary;
    }
}
.chat-header {
    padding-left: 0;
    margin-top: 10px;
    @include media-breakpoint-up(lg) {
        padding-left: 1.5rem;
        margin-top: 70px;
    }
}
.chat_list {
    padding-left: 0;
    padding-right: 0;
    @include media-breakpoint-up(lg) {
        padding-left: 30px;
        padding-right: 15px;

    }
}

.btn-toogle-footer {
    .far  {
        transition: all 1s;
    }
    &.collapsed {
        .far {
            transform: rotate(180deg);
        }
    }
}

@include media-breakpoint-down(lg) {
    body.h-100 {
        overflow-y: scroll;
    }

    .tooltip {
        z-index: 1201;
    }
}
