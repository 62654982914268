@import "custom";
@import "~@fortawesome/fontawesome-free/css/all.css";


/* Sticky footer styles
-------------------------------------------------- */
html {
    position: relative;
    min-height: 100%;
}

body {
    font-family: Default OBS Font, arial, sans-serif !important;
}

/* Custom page CSS
-------------------------------------------------- */
/* Not required for template or sticky footer method. */

body > .container {
    padding: 90px 15px 0;
}

code {
    font-size: 80%;
}

/*
 Distribution
 */

.card-header {
    background-color: transparent;
    border: none;

}

.card-footer {
    background-color: transparent;
    border: none;

}

.card-body {
    background-color: transparent;
    border: none;

}

.card {
    border: none;
}

.a-dark {
    color: #212529;
    text-decoration: underline;
}

.a-light {
    color: #FFFFFF;
    text-decoration: underline;
}

.a-muted {
    color: #6c757d;
    text-decoration: underline;
}

.text-black {
    color: #000000 !important;
}

.h1-header {
    margin-left: 208px;
    color: white;
}

.main-bg {
    background-repeat: no-repeat;
    background-position: 0 0;
    background-image: linear-gradient(180deg, rgba(theme-color("secondary"), .2) 0%, rgba(#fff, .2) 100%);
    background-color: transparent;
    background-size: 100% 171px;
}

input {
    -webkit-writing-mode: horizontal-tb !important;
    text-rendering: auto;
    letter-spacing: normal;
    word-spacing: normal;
    text-transform: none;
    text-indent: 0;
    text-shadow: none;
    display: inline-block;
    text-align: start;
    background-color: #ffffff;
    -webkit-rtl-ordering: logical;
    cursor: text;
    margin: 0;
    /*font: 400 13.3333px Arial;*/
    padding: 1px 2px;
    border-width: 2px;
    border-style: inset;
    border-color: #E8E8E8;
    border-image: initial;
}

.text-lightblue {
    color: theme-color("secondary")
}

.text-darkblue {
    color: theme-color("primary");
}

.overlay {
    z-index: 1300;
    height: calc(var(--doc-height) - 108px);
    /* Add the blur effect */

}

.bg-blurred {
    filter: blur(8px);
    -webkit-filter: blur(8px);
}

.avatar-edit-icon {
    height: 30px;
    width: 30px;
    background-color: #FFFFFF;
    border-radius: 50%;
    position: absolute;
    display: inline-block;
    top: 1rem;
    right: 1rem;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.7);

    > i {
        position: relative;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -60%);
        font-size: 20px;
    }
}

.transparent-select {
    border: 0 solid #fff;
    background-color: transparent;
}

.text-online {
    color: #76E650;
}

.text-offline {
    color: #E65076;
}

.rounded-input {
    border-radius: 0.5rem !important;
}

.login-image {
    position: relative;
    background-image: url('../images/startseite_visual_cr.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top left;
}

.info {
    position: absolute;
    top: 15px;
    right: 15px;
}

.info a, .label-mbeon {
    color: theme-color("secondary");
}

* {
    scrollbar-color: theme-color("primary") theme-color("secondary");
    scrollbar-width: thin;
}

::-webkit-scrollbar,
::-webkit-scrollbar-thumb,
::-webkit-scrollbar-track {
    width: 12px;
    border: none;
    background: transparent;
}

::-webkit-scrollbar-button,
::-webkit-scrollbar-track-piece,
::-webkit-scrollbar-corner,
::-webkit-resizer {
    display: none;
}

::-webkit-scrollbar-thumb {
    background-color: theme-color("primary");
}

::-webkit-scrollbar-track {
    background-image: linear-gradient(90deg, transparent 5px, theme-color("secondary") 5px, theme-color("secondary") 7px, transparent 7px);
    background-color: transparent;
}

.pb-footer {
    padding-bottom: 116px !important;

    @include media-breakpoint-down(lg) {
        padding-bottom: 48px !important;
    }
}
