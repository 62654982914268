@import "app.scss";

html, body {
    height: 100%;
}

.mbeon-login {
    height: var(--doc-height);


}

.card {
    height: 370px;
    margin-top: auto;
    margin-bottom: auto;
    width: 400px;
    background-color: transparent !important;
}

.card-header h2 {
    color: theme-color("primary");
}

.social_icon {
    position: absolute;
    right: 20px;
    top: -45px;
}

input:focus {
    outline: 0 !important;
    box-shadow: 0 0 0 0 !important;

}

.remember {
    color: white;
}

.remember input {
    width: 20px;
    height: 20px;
    margin-left: 15px;
    margin-right: 5px;
}

.links {
    color: white;
}

.links a {
    margin-left: 4px;
}

.login-footer {
    position: fixed;
    bottom: 0;
    height: auto;
    line-height: 60px;
}

.logo {
    max-width: 160px;
}
@include media-breakpoint-up(lg) {
    .logo {
        max-width: 270px;
    }
}
